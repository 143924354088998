import React, { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { localeDataGrid } from '~/configs/localeDataGrid';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList';
import { tablePaginationClasses } from '@mui/material';
import { useConditionState } from '~/modules/screener/containers/useConditionState';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import { defaultColumns } from '../ScreenerResultTable/Columns';
import { useGood178 } from '~/pages/good178_stock/modules/useGood178';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
const tableCss = css `
  width: 100%;
  height: 100%;
  .${gridClasses.cell}, .${gridClasses.columnHeader} {
    padding: 5px;
  }
  .${gridClasses.row}:hover {
    cursor: pointer;
    border: 1px solid ${'#333333'};
  }
  .${gridClasses.menuIcon} {
    display: none;
  }
  .${gridClasses.footerContainer} {
    border: 1px solid ${'#333333'};
    height: 40px;
    min-height: 40px;
  }
  .${tablePaginationClasses.toolbar} {
    height: 40px;
  }
  .${gridClasses.virtualScroller} {
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #333333;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #aaaaaa;
    }
  }
`;
export const ScreenerSimpleTable = memo(function ScreenerSimpleTable(props) {
    const changeSymbol = useChartingChangeSymbolIntervalFn(props.useChart);
    const { isPhone } = useMedia();
    const { state, acts } = useConditionState.useContainer();
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const futuresDictionary = useStockToFutureMapper();
    const biggestValue = Math.max(...(state.data ?? []).map(state.currentResource.referer));
    const data = state.data
        ?.sort(state.currentResource.sorter)
        ?.map(item => state.currentResource?.mapper(item, biggestValue)) ?? [];
    return (<DataGrid css={tableCss} density='compact' onSelectionModelChange={model => {
            const symbol = model.length === 1 ? model[0] : '';
            acts.setCurrentSymbol(symbol);
            changeSymbol({ symbol });
        }} localeText={localeDataGrid} hideFooterPagination={false} hideFooterSelectedRowCount={true} hideFooter={false} rows={data} pageSize={20} headerHeight={50} rowHeight={50} columns={defaultColumns(isPhone, dictionary, futuresDictionary ?? {}, state.date)}/>);
});
export const Good178ScreenerSimpleTable = memo(function Good178ScreenerSimpleTable(props) {
    const changeSymbol = useChartingChangeSymbolIntervalFn(props.useChart);
    const { isPhone } = useMedia();
    const { state, acts } = useConditionState.useContainer();
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const futuresDictionary = useStockToFutureMapper();
    const latestTradeString = useSnapshot(staticStore).tradedDate.intraday;
    const intraDay = useSnapshot(staticStore.tradedDate.intraday).format('YYYY-MM-DD');
    const biggestValue = Math.max(...(state.data ?? []).map(state.currentResource.referer));
    const data = state.data
        ?.sort(state.currentResource.sorter)
        .slice(0, 20)
        ?.map(item => state.currentResource?.mapper(item, biggestValue)) ?? [];
    const good178Symbol = useGood178({
        bs: 'b',
        date: intraDay,
    })?.data?.symbols;
    const good178SymbolList = state.power === 'strong' ? data.filter(item => good178Symbol?.includes(item.symbol)) : data;
    return (<DataGrid css={tableCss} density='compact' onSelectionModelChange={model => {
            const symbol = model.length === 1 ? model[0] : '';
            acts.setCurrentSymbol(symbol);
            changeSymbol({ symbol });
        }} localeText={localeDataGrid} hideFooterPagination={false} hideFooterSelectedRowCount={true} hideFooter={false} rows={good178SymbolList} pageSize={20} headerHeight={50} rowHeight={50} columns={defaultColumns(isPhone, dictionary, futuresDictionary ?? {}, state.date)}/>);
});
