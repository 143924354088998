import useSWR from 'swr';
import { apirc } from '~/configs/apirc';
export const useGood178 = (options, config) => {
    const refreshInterval = config?.refreshInterval || 1000 * 60;
    const { bs, date } = options;
    const res = useSWR(['/api/screener/good178', bs, date], async () => {
        if (!bs || !date)
            return;
        const $res = await apirc.marketInsightService.GET('/api/screener/good178', {
            params: {
                query: {
                    bs,
                    date,
                },
            },
        });
        return $res.data;
    }, {
        refreshInterval,
    });
    return res;
};
